import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
import _ from 'lodash';
import GlobalMixin from '@/mixins/global.mixin';

export const getGreenFilm = async (label = '') => {
    let variables = {}
    if(!isNil(label) && label !== '' ){
        variables = {
            ...variables,
            label:label
        }
    }
    try {
        const {
			data: { GetGreenFilm }
		} = await apollo.query({
        query: gql`
          query ($label: Int) {
            GetGreenFilm(Label: $label) {
              ${fields}
            }
          }
        `,
        variables,
        fetchPolicy: 'no-cache',
      });

      return GetGreenFilm;

    } catch (error) {
      console.log(error)
      GlobalMixin.resolveGqlError(error)
    }
};

export const updGreenFilm = async (GreenFilmId,UpdatedGreenFilm) => {
    try {
        const { data } = await apollo.mutate({
            mutation: gql`
              mutation ($GreenFilmId: ID!, $UpdatedGreenFilm: GreenFilmInput!) {
                UpdGreenFilm(GreenFilmId: $GreenFilmId, UpdatedGreenFilm: $UpdatedGreenFilm){
                    ${fields}
                }
              }
            `,
            variables: {
              GreenFilmId: GreenFilmId,
              UpdatedGreenFilm: UpdatedGreenFilm,
            },
        });
        return data.UpdGreenFilm;
      } 
      catch (error) {
        console.log(error)
        GlobalMixin.resolveGqlError(error)
    }
}

const fields = `
    id
    publisher {
        name
        firstName
    }
    numDocPr1
    numDocPr2
    type
    a1ElectricityFromLocalNetwork
    a1Value
    a2GreenEnergy
    a2Value
    a3LedLighting
    a3Value
    b1VehiculeEuro5
    b1Value
    b2VehiculeGreen
    b2Value
    b3AccomodationCloseBy
    b3Value
    b4GreenLabelAccomodation
    b4Value
    c1TabWater
    c1TabWaterValue
    c1WaterFontain
    c1WaterFontainValue
    c2Catering
    c2CateringValue
    c2Restaurant
    c2RestaurantValue
    c3ReusableDishes
    c3Value
    c4WashableCup
    c4WashableCupValue
    c4RecyclableCup
    c4RecyclableCupValue
    d1MaterialSupplierFsc
    d1MaterialSupplierFscValue
    d1MaterialSupplierEmas
    d1MaterialSupplierEmasValue
    d2MaterialRecycled
    d2MaterialRecycledValue
    d3ReusableStageMaterial
    d3Value
    d4ElectronicInfoExchange
    d4Value
    e1SortWaste
    e1Value
    f1ComPressFile
    f1Value
    f2ComClip
    f2Value
    f3ComFromProduction
    f3Description
    f3Value
`